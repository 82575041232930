export const MenuItems = [
 {
    title : "Company",
    url   : "/company",
    cName : "nav-links",
 },
 {
    title : "Businesses",
    url   : "/businesses",
    cName : "nav-links",
 },
 {
    title : "Responsibility",
    url   : "/responsibility",
    cName : "nav-links",
 },
 {
    title : "Publication",
    url   : "/publication",
    cName : "nav-links",
 },
//  {
//    title : "Track Your Order",
//    url   : "/",
//    cName : "nav-links",
// },
//  {
//    title : "Log In",
//    url   : "/",
//    cName : "nav-login",
//  }  

]