// AdminPage.js

import React from 'react';
import Navbar from "../components/Navbar";
import Home from './Home';


const User = () => {
  return (
    <>
        <Navbar page="/"/>
        <Home/>
    </>
  );
};

export default User;
