import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import './TrackUserOrder.css';

function TrackUserOrder1() {
  const [index, setIndex] = useState(0);
  const maxIndex = 9;

  useEffect(() => {
    // Membuat interval untuk mengubah indeks setiap 5 detik (5000 milidetik)
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % (maxIndex + 1));
    }, 5000); // Ubah 5000 dengan interval waktu dalam milidetik

    // Membersihkan interval saat komponen tidak lagi digunakan
    return () => clearInterval(intervalId);
  }, []); // Dependensi kosong untuk memastikan efek hanya dijalankan sekali setelah render pertama

  return (
    <>
      <Navbar page="/" />
      <div className="containers">
        <div className="hero1">
          <img src="track your order.jpg" alt="my-img" />
          <h1 className="h5">Track Your Order</h1>
        </div>
      </div>
      <div className="user-order-lists">
        <div key={index} className="order-box">
          <img src={`production${index}.svg`} alt={`myimg ${index}`} />
          <img src={`production ${index}.svg`} alt={`myimg ${index}`} />
        </div>
        <div className="hero-footer1">
          <img src="footer.png" alt="my-img1"/>
          <div className="footer-text1">
            <img src="footer-text.png" alt="my-img2"/>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrackUserOrder1;
