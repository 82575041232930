// AdminPage.js

import React from 'react';
import Navbar from "../components/Navbar";
import Home from '../routes/Home';


const AdminPage = () => {
  return (
    <>
        <Navbar page="admin"/>
        <Home/>
    </>
  );
};

export default AdminPage;
