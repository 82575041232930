// db.js
// db.js
export const data = [
    { invoiceId: '12345', lastUpdated: '2023-01-01', status: 'in progress' },
    { invoiceId: '12346', lastUpdated: '2023-01-02', status: 'produced' },
    { invoiceId: '12347', lastUpdated: '2023-01-03', status: 'packed' },
    { invoiceId: '12348', lastUpdated: '2023-01-04', status: 'shipping' },
    { invoiceId: '12349', lastUpdated: '2023-01-05', status: 'order completed' },
    // Add more orders as needed
  ];
  