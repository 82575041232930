// SearchBar.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './SearchBar.css';
import { data } from '../database/db'; // Import your data

const SearchBar = ({ placeholder }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    const matchingOrder = data.find((order) => order.invoiceId === searchTerm);

    if (matchingOrder) {
      // Navigate to the desired page, for example, "/order-details/:invoiceId"
      window.location.href = `/${searchTerm}`;
    } else {
      // Handle case where invoiceId is not found
      console.log('Invoice ID not found');
    }
  };

  return (
    <div className="search-bar-container">
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
      />
      <div className="mybutton" onClick={handleSearch}>
        <FontAwesomeIcon icon={faSearch} />
      </div>
    </div>
  );
};

export default SearchBar;
