import Navbar from "../components/Navbar"

function Whatwedo () {
    return(
        <>
            <Navbar page='/'/> 
        </>
    )
}


export default Whatwedo