import React, { useState, useEffect } from 'react';
import './NavbarStyle.css';
import { MenuItems } from './MenuItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

// Navbar.js

// ... (other imports)

function Navbar(props) {
    const [isBarsIconVisible, setIsBarsIconVisible] = useState(true);
    const [clicked, setClicked] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
  
    const toggleIcon = () => {
      setIsBarsIconVisible(!isBarsIconVisible);
      setClicked(!clicked);
    };
    const navMenuClass = clicked ? 'nav-menu active' : 'nav-menu';
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    useEffect(() => {
      // Check if the current URL is /admin
      setIsAdmin(window.location.pathname === '/admin' || '/order-list');
    }, []);
  
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsHidden(true);
        setTimeout(() => {
          setIsHidden(false);
        }, 500);
      }
    };
  
    return (
      <nav className={`NavbarItems ${isHidden ? 'hidden' : ''}`}>
        <h1 className="nav-logo">
          <Link className="logo" to="/">
            <img src='ahatex-logo.svg' alt='ahatex-logo'/>
          </Link>
          <p className='logoname'>AHATEX</p>
        </h1>
  
        <div className='menu-icons'>
          <FontAwesomeIcon className='icon-class' icon={isBarsIconVisible ? faBars : faTimes} onClick={toggleIcon} />
        </div>
  
        <ul className={navMenuClass}>
          {MenuItems.map((item, index) => (
            <li key={index}>
              <Link className={item.cName} to={item.url}>
                {item.title}
              </Link>
            </li>
          ))}
  
          {isAdmin ? (
            <li>
              <Link className="nav-links" to={props.page === 'admin' ? "/order-list" : "/track-your-order"}>
                {props.page === 'admin' ? "Order List" : "Track Your Order"}
              </Link>
            </li>
          ) : (
            <li>
              <Link className="nav-links" to="/track-your-order">
                Track Your Order
              </Link>
            </li>
          )}
  
          <button>Log In</button>
        </ul>
      </nav>
    );
  }
  
  export default Navbar;
  