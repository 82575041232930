// ListOrder.js
import React, { useState } from 'react';
import './ListOrder.css';
import { data } from '../database/db';
import { addToIndexArray } from '../database/db1';

const ListOrder = () => {
  const [orders, setOrders] = useState(data);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggleDropdown = (index) => {
    setDropdownOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleStatusChange = (index, selectedStatus) => {
    // Update the status for the selected order
    const updatedOrders = [...orders];
    updatedOrders[index].status = selectedStatus;
    setOrders(updatedOrders);
    toggleDropdown(index);
  };

  return (
   
      <div>
        {orders.length === 0 ? (
          <p>You currently have no orders.</p>
        ) : (
          <div className="table-container">
            <div className="table-row table-header">
              <div className="table-cell">Invoice ID</div>
              <div className="table-cell">Last Updated</div>
              <div className="table-cell">Status</div>
              {/* Remove the Options header */}
            </div>
            {orders.map((item, index) => (
              <div key={index} className="table-row">
                <div className="table-cell">{item.invoiceId}</div>
                <div className="table-cell">{item.lastUpdated}</div>
                <div className="table-cell">
                  <div className="dropdown">
                    <button
                      className="dropdown-toggle"
                      onClick={() => toggleDropdown(index)}
                    >
                      {item.status}
                    </button>
                    {dropdownOpen === index && (
                      <div className="dropdown-menu">
                        {['in progress', 'produced', 'packed', 'shipping', 'order completed'].map(
                          (status, statusIndex) => (
                            <div
                              key={statusIndex}
                              className="dropdown-item"
                              onClick={() => handleStatusChange(index, status)}
                            >
                              {status}
                              <addToIndexArray index={index}/>
                              
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {/* Remove the Options cell */}
              </div>
            ))}
          </div>
        )}
      </div>
    
  );
};

export default ListOrder;
