
import "./Home.css";

function Home() {
  return (
    <>
      <div className='hero'>
          <div className="hero-text">
            <h1 className="h1">Intertwined</h1>
            <h1 className="h2">a Better Future</h1>
            <p>We are an one stop solution textile and garment manufacturing company with a purpose to support your businesses with the best products or services</p>
          </div>
        </div>
        <div>
          <img src="home - background 1.jpg" alt="my-img" />
        </div>
        <div className="hero-content">
             <img src="Screen2.png" alt="my-img" />
        </div>
        <div className="hero-footer">
          <img src="footer.png" alt="my-img"/>
          <div className="footer-text">
            <img src="footer-text.png" alt="my-img"/>
          </div>
        </div>
    </>
  );
}

export default Home;
