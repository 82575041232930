import React from 'react';
import Navbar from '../components/Navbar';
import SearchBar from '../components/SearchBar'
import './TrackOrder.css';

function TrackYourOrder() {
  return (
    <>
      <Navbar />
      <div className='container'>
        <div className="hero">
          <img src="track your order.jpg" alt="my-img" />
          <h1 className="h3">Track Your Order</h1>
          <p className='text-p'>Look it up with your invoice ID</p>
        </div>
      </div>
      <SearchBar placeholder="Invoice ID"/>
    </>
  );
}

export default TrackYourOrder;
