import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import './OrderSearch.css';
import { data } from '../database/db'; // Import the data array

// Import necessary dependencies and styles

const OrderSearch = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showPlusIcon, setShowPlusIcon] = useState(true);

  useEffect(() => {
    const invoiceExists = data.some(item => item.invoiceId === searchTerm);
    setShowPlusIcon(!invoiceExists);
  }, [searchTerm]);

  const handleInputChange = (event) => {
    const inputInvoiceId = event.target.value;
    setSearchTerm(inputInvoiceId);
  };

  const handleSearch = () => {
    if (searchTerm.trim() !== '') {
      onSearch(searchTerm);
    }
  };

  const handleAddClick = () => {
    if (searchTerm.trim() !== '' && showPlusIcon) {
      // Create a new invoice object
      const newInvoice = { invoiceId: searchTerm, product: 'Product A', amount: 100 };

      // Update the data array in db.js
      data.push(newInvoice);

      // Clear the input after adding
      setSearchTerm('');
    }
  };

  return (
    <div className="search-container">
      <input
        className={`input-2 ${showPlusIcon ? '' : 'error'}`}
        type="text"
        placeholder='Invoice ID'
        value={searchTerm}
        onChange={handleInputChange}
      />
      <div className='mybutton-2' onClick={showPlusIcon ? handleAddClick : handleSearch}>
        <FontAwesomeIcon icon={showPlusIcon ? faPlus : faSearch} />
      </div>
    </div>
  );
};

export default OrderSearch;
