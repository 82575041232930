import React from 'react';
import Navbar from '../components/Navbar';
import './UserList.css';
import OrderSearch from './OrderSearch';
import ListOrder from './ListOrder';

function UserList() {
  return (
    <>
      <Navbar page="admin"/>
      <div className='container'>
        <div className="hero">
          <img src="track your order.jpg" alt="my-img" />
          <h1 className="h4">Order List</h1>
        </div>
        <OrderSearch />
      </div>
      <div className='order-lists'>
        <ListOrder/>
      </div>
    </>
  );
}

export default UserList;