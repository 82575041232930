import Navbar from "../components/Navbar";

function About () {
    return(
        <>
            <Navbar/>
            <h1>This is 3 P</h1>
        </>
    )
}


export default About