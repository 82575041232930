import { Route, Routes } from "react-router-dom";
import About from "./routes/About";
// import Approach from "./routes/Ourapproach";
import Whatwedo from "./routes/Whatwedo";
import Contact from "./routes/Contacts";
import AdminPage from "./admin/AdminPage";
import User from "./routes/User";
import TrackYourOrder from "./routes/TrackOrder";
import UserList from "./admin/UserList";
import TrackUserOrder1 from "./routes/TrackUserOrder1";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<User />} />
        <Route path="/admin" element={<AdminPage />} />
        {/* <Route path='/our-approach' element={<Approach/>}/> */}
        <Route path="/about" element={<About />} />
        <Route path="/company" element={<Whatwedo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/track-your-order" element={<TrackYourOrder />} />
        <Route path="/order-list" element={<UserList />} />
        <Route path="/12345" element={<TrackUserOrder1 />} />

      </Routes>
    </div>
  );
}

export default App;
